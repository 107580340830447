import WithApp from '../_utils/withApp.tsx'
import { Context } from '../_types'
import { setBrowserCookie } from '../_utils'
import { cookieKeys } from '../_constants'

const Component = () => (
  <div>
    <div className="flex flex-row flex-wrap gap-6">
      <button
        data-cy="change-to-pl"
        type="button"
        className="shadow-all-directions"
        onClick={e => {
          e.preventDefault()
          setBrowserCookie(cookieKeys.locale, 'pl')
          window.location.reload()
        }}>
        <img
          className="cursor-pointer grayscale-[20%] hover:grayscale-0"
          src="https://purecatamphetamine.github.io/country-flag-icons/3x2/PL.svg"
          alt="język polski"
          width="35"
          height="35"
        />
      </button>

      <button
        data-cy="change-to-en"
        type="button"
        className="shadow-all-directions"
        onClick={e => {
          e.preventDefault()
          setBrowserCookie(cookieKeys.locale, 'en')
          window.location.reload()
        }}>
        <img
          className="cursor-pointer grayscale-[20%] hover:grayscale-0"
          src="https://purecatamphetamine.github.io/country-flag-icons/3x2/GB.svg"
          alt="change page language to english"
          width="35"
          height="35"
        />
      </button>
    </div>
  </div>
)

export default function ChangeLanguage({ context }: { context: Context }) {
  return (
    <WithApp context={context}>
      <Component />
    </WithApp>
  )
}
